import { React } from 'react';
//import { navigate } from "gatsby"
//import MessageOverlay from '../components/messageOverlay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import LayoutSecondary from '../components/layout/LayoutSecondary';


const Success = ({ location }) => {
  const params = new URLSearchParams(location.search);
  //const receivedName = params.get("name");
  const errorType = params.get("type");


  const title = 'Error!';
  const description = 'Error page';

  return (
    <LayoutSecondary title={title} description={description} article={false}>
      <section id="error" className="pt-20 md:pt-20">
        <div className="container mx-auto px-8 lg:flex">
          <h1 className="block text-4xl lg:text-5xl xl:text-6xl text-red-700 font-bold leading-none w-full"><FontAwesomeIcon icon={faExclamationCircle} /> An error occurred</h1>

        </div>
        <div className="container mx-auto px-8 lg:flex">
          <p className="text-xl lg:text-2xl mt-6 font-light">There has been an error in the {errorType} procedure. Our support staff has been advised and will contact you in due course.</p>
        </div>
      </section>
    </LayoutSecondary>
  );
}

export default Success;
